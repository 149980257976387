import React from 'react';
import { Select, MenuItem, InputLabel, Card, FormControl, CardContent, Box, Button } from '@material-ui/core';
import { useEntity } from '@backstage/plugin-catalog-react';
import { LogTable } from './LogTable';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { Progress, WarningPanel, Link } from '@backstage/core-components';
import { LogsLink } from './LogsLink';
import fetchAuthorization from '../../fetchAutorization';

let staticContext: any;
let lastLogHash: any;
let initRows: Array<any> = [];
let error : boolean = false;

export const Logs = () => {

  const { entity } = useEntity();
  const config = useApi(configApiRef);
  const argocdServiceURL = config.getString("backend.baseUrl")

  const [data, setData] = React.useState({
    env: '',
    context: '',
    service: '', 
    services: [],
    pod: '',
    pods: [],
    rows: initRows,
    loading: false,
    disableEnv: false,
    disableService: true,
    disablePod: true,
    disableButton: true,
    showAlert: false,
    errorMessage: '',
    errorTitle:''
  });
  const repository = entity.metadata.name;
  const environments: Array<any> = ["dev", "stage", "prod"];


  const initConfig = async () => {
    if (staticContext) return;
    if(error) return;

    const queryString: string = [
      `repository=${repository}`
    ].join('&');
    const url: string = argocdServiceURL + `/api/argocd/context?${queryString}`;
    try{
      const {body, res} = await fetchAuthorization(url);

      staticContext = body.name;

      if(res.status == 401){
        error = true;
        setData({...data, disableEnv: true, showAlert: true, errorMessage: 'Você não tem autorização para acessar esse módulo.',  errorTitle:'Acesso não autorizado.'});
        return;
      }
      if(staticContext === ""){
        error = true;
        setData({...data, errorMessage: 'Nenhum contexto encontrado'}); 
      }else{
        setData({...data, context: staticContext});
      }
    }catch (error) {
      console.error(error);
    }
   
  };
  
  initConfig();
  

  const envHandler = async (event: any) => {
    setData({...data, loading: true});

    const queryString: string = [
      `environment=${event.target.value}`,
      `repository=${repository}`,
      `context=${staticContext}`
    ].join('&');
    const url: string = argocdServiceURL + '/api/argocd/services?' + queryString;
    
    const {body, res} = await fetchAuthorization(url);
    
    if (res.status != 200) {
      error = true;
      let temp_data = {
        ...data, 
        env: event.target.value,
        context: staticContext,
        service: '',
        services: [],
        pod: '',
        pods: [],
        rows: [],
        loading: false,
        disableService: true,
        disablePod: true,
        disableButton: true,
        showAlert: true,
      };

      if(res.status == 401){
        setData({
          ...temp_data, 
          disableEnv: true,
          errorMessage: 'Você não tem autorização para acessar esse módulo.',  
          errorTitle:'Acesso não autorizado.'
        });
      }else{
        setData({
          ...temp_data,
          errorMessage: 'Nenhum serviço relacionado a esse repositório.', 
          errorTitle:'Verifique se existem serviços relacionados a esse repositório.'
        });
      }





      return;
    }

    let updatedServices = [];
    //const jsonResult = await response.json();
    const jsonResult = body;
    updatedServices = jsonResult.map((item: any) => {
      return {name: item.appname}
    });
    setData({
      ...data, 
      env: event.target.value,
      context: staticContext,
      loading: false,
      service: '',
      services: updatedServices,
      pod: '',
      pods: [],
      rows: [],
      disableService: false,
      disablePod: true,
      showAlert: false,
    });
  };

  const serviceHandler = async (event: any) => {
    setData({...data, loading: true});

    const queryString: string = [
      `environment=${data.env}`,
      `context=${data.context}`,
      `application=${event.target.value}`,
    ].join('&');
    const url: string = argocdServiceURL + '/api/argocd/pods?' + queryString;

    const {body, res} = await fetchAuthorization(url);
    
    if (res.status != 200) {
      setData({
        ...data, 
        service: event.target.value,
        pod: '',
        pods: [],
        rows: [],
        loading: false,
        disablePod: true
      });
      return;
    }

    let updatedPods = [];
    const jsonResult = body;
    updatedPods = jsonResult.map((item: any) => {
      return {name: item}
    });
    setData({
      ...data,
      service: event.target.value,
      pod: '',
      pods: updatedPods,
      rows: [],
      loading: false,
      disablePod: false
    });
  };

  const podHandler = async (event: any) => {
    lastLogHash = null;
    setData({
      ...data,
      pod: event.target.value,
      rows: [],
      disableButton: false
    });
  };

  const tailLog = async () => {
    setData({...data, loading: true});

    const queryString: string = [
      `environment=${data.env}`,
      `context=${data.context}`,
      `application=${data.service}`,
      `pod=${data.pod}`,
      `tailLines=60`,
      `lastLogHash=${lastLogHash}`
    ].join('&');
    const url: string = argocdServiceURL + '/api/argocd/logs?' + queryString;
    const {body} = await fetchAuthorization(url);
    const contents: Array<any> = body;
    
    contents.forEach((item: any) => {
      lastLogHash = item.hash;
      data.rows.push({name: item.content, timeStamp: item.timeStamp});
    });
    setData({...data, rows: data.rows, loading: false});
  }


  return (
    <Card>
      <CardContent>
        <Box sx={{ minWidth: 120 }}>
          <Progress style={{display: data.loading ? 'block' : 'none'}}/>

          <FormControl fullWidth>
            <InputLabel id="select-service-label">Ambiente:</InputLabel>
            <Select
              disabled={data.disableEnv}
              labelId="select-service-label"
              id="countrySelect" 
              label="Ambiente"
              value={data.env}
              onChange={(event) => envHandler(event)}>
              {environments.map((item, index: number) => (
              <MenuItem key={index} value={item}>
                <em>{item}</em>
              </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="select-service-label">Selecione o Serviço:</InputLabel>
            <Select
              disabled={data.disableService}
              labelId="select-service-label" 
              id="countrySelect" 
              label="Serviço"
              value={data.service}
              onChange={(event) => serviceHandler(event)}>
              {data.services.map(({name}, index: number) => (
              <MenuItem key={index} value={name}>
                <em>{name}</em>
              </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="select-service-label">Selecione o Pod:</InputLabel>
            <Select
              disabled={data.disablePod}
              labelId="select-service-label" 
              id="countrySelect" 
              label="Pod"
              value={data.pod}
              onChange={(event) => podHandler(event)}>
              {data.pods.map(({name}, index: number) => (
              <MenuItem key={index} value={name}>
                <em>{name}</em>
              </MenuItem>
              ))}
            </Select>
          </FormControl>

        </Box>
        <Box style={{marginTop: "20px"}}>
          <LogTable data={{rows: data.rows}}/>
          <Progress style={{display: data.loading && data.rows.length > 0 ? 'block' : 'none'}}/>
          <Button disabled={data.disableButton} variant="contained" onClick={(_) => tailLog()}>Carregar</Button>
          <LogsLink data={{service: data.service, env: data.env, context: data.context, pod: data.pod}} />
        </Box>
        <Box style={{marginTop: "20px"}}>
            {data.showAlert &&(
              <WarningPanel title={data.errorTitle} >
                  {data.errorMessage.includes('contexto') ? (
                  <React.Fragment>
                    {data.errorMessage}{' '}
                    <Link to="https://pravaler.atlassian.net/wiki/spaces/devops/pages/1678114823/T+picos+Gerais">
                      Mais informações consulte a documentação
                    </Link>
                  </React.Fragment>
                ) : (
                  data.errorMessage
                )}
              </WarningPanel>
            )}
        </Box>
      </CardContent>
    </Card>
  );
};
