import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import fetchAuthorization from '../../fetchAutorization';
let staticConfig: any;

export const ArgoCDLink = (props: any) => {
  const [data, setData] = React.useState({configLoaded: false});

  const initConfig = async () => {
    if (staticConfig) return;
    
    const config = useApi(configApiRef);
    const argocdServiceURL = config.getString("backend.baseUrl") + '/api/argocd/config';
   
    try {
      const {body} = await fetchAuthorization(argocdServiceURL);
      staticConfig = body;
      setData({...data, configLoaded: true});
    } catch (error) {
      console.error(error);
    }
  }
  
  initConfig();
  
  if (!props.data.service || !props.data.env || !props.data.context) {
    return (<Grid><b></b></Grid>); 
  }
  if (!staticConfig[props.data.env]) {
    return (<Grid><b>Nenhuma configuração para o ambiente selecionado!</b></Grid>);
  }
  if (!staticConfig[props.data.env][props.data.context]) {
    return (<Grid><b>Nenhuma configuração para o ambiente e contexto selecionado!</b></Grid>);
  }

  let panelConfig: any = staticConfig[props.data.env][props.data.context];
  let argocdPanelUrl: string = `https://${panelConfig.dns}/applications/kube-system/${props.data.service}?view=tree&resource=`

  return (
    <Grid>
      <Button
        variant="contained"
        color="primary"
        target="_blank"
        href={argocdPanelUrl}
      >Ir para o ArgoCD</Button>
    </Grid>
  );
};