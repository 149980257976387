import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import fetchAuthorization from '../../fetchAutorization';
let staticConfig: any;

export const GrafanaLink = (props: any) => {

  const [data, setData] = React.useState({configLoaded: false});

  const initConfig = async () => {
    if (staticConfig) return;
    
    const config = useApi(configApiRef);
    const argocdServiceURL = config.getString("backend.baseUrl") + '/api/argocd/config';

    try {
      const {body} = await fetchAuthorization(argocdServiceURL);
      staticConfig = body;
      setData({...data, configLoaded: true});
    } catch (error) {
      console.error(error);
    }
  }
  
  initConfig();
  
  if (!props.data.service || !props.data.env || !props.data.context) {
    return (<Grid><b></b></Grid>);
  }
 


  let panelConfig: any = staticConfig[props.data.env][props.data.context];
  let grafanaPanelUrl: string = `${panelConfig.dashboard.replace('kiosk', '')}.&var-BRANCH=${props.data.service}`
  
  return (     
    <Grid>
      <Button
          variant="contained"
          color="primary"
          target="_blank"
          href={grafanaPanelUrl}
        >Ir para o Grafana
      </Button>
    </Grid> 
  );
};