export class CustomCookieService {
  set(key: string, value: string, attributes: any): void {
    if (typeof document === 'undefined') return;

    const mergedAttributes: any = { path: '/', ...attributes };

    if (typeof mergedAttributes.expires === 'number') {
      if (mergedAttributes.expires > 0) {
        const now: Date = new Date();
        now.setTime(now.getTime() + 1000 + mergedAttributes.expires * 864e5);
        mergedAttributes.expires = now.toUTCString();
      }
    }

    try {
      value = JSON.stringify(value); 
    } catch (e) {}

    const list: string[] = [];
    for (const attributeName in attributes) {
      list.push(`${attributeName}=${attributes[attributeName]}`);
    }
    const stringifiedAttributes: string = list.join('; ');
    document.cookie = `${key}=${value}; ${stringifiedAttributes}`;
  }

  get(key: string): string | undefined {
    if (typeof document === 'undefined') return undefined;

    const name: string = `${key}=`;
    const decodedCookie: string = decodeURIComponent(document.cookie);
    const cookieParts: string[] = decodedCookie.split(';');

    for (let i = 0; i < cookieParts.length; i++) {
      let part: string = cookieParts[i].trim();
      if (part.indexOf(name) === 0) {
        const cookieValue: string = part.substring(name.length, part.length);

        return this.decode(this.removerAspasDuplas(cookieValue));
      }
    }
    return undefined;
  }

  decode(contents: string): string {
    return contents.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
  }

  remove(cookieName: string) {
    document.cookie = `${cookieName}=; Max-Age=0; path=/`;
  }

  private removerAspasDuplas = (token: string) => {
    while (token.startsWith('"')) {
      token = token.slice(1);
    }

    while (token.endsWith('"')) {
      token = token.slice(0, -1);
    }

    return token;
  };
}