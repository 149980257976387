import { CustomCookieService } from "./customCookie";
import fetch from 'node-fetch';

async function fetchAuthorization(url: string) {
  const customCookieService = new CustomCookieService();
  const token = customCookieService.get('tokenMicrosoft');

  const response = await fetch(url, { headers: {
    Authorization: `Bearer ${token}`}, });
  const data = await response.json();
  return { body: data, res: response };
}

export default fetchAuthorization;
  