import React from 'react';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

let lastSize: number = 0;
let lastLine: number = 0;

export const LogTable = (props: any) => {

  let currentRowLength: number = props.data.rows.length;

  if (currentRowLength == 0) {
    return (<div></div>);
  }

  if (currentRowLength != lastSize)
    lastLine = currentRowLength - (currentRowLength - lastSize);
  lastSize = currentRowLength;
  
  return (
    <Card>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell>Log</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {props.data.rows.map((row: any, index: any) => (
            <TableRow key={index} selected={index > 0 && index == (lastLine-1)}>
              <TableCell component="th" scope="row">{row.timeStamp}</TableCell>
              <TableCell component="th" scope="row">{row.name}</TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};