import React from 'react';
import { Select, MenuItem, InputLabel, Card, FormControl, CardContent, Box } from '@material-ui/core';
import { useEntity } from '@backstage/plugin-catalog-react';
import { GrafanaFrame } from './GrafanaFrame';
import { GrafanaLink } from './GrafanaLink';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { Progress, WarningPanel, Link } from '@backstage/core-components';
import fetchAuthorization from '../../fetchAutorization';

let staticContext: any;
let error : boolean = false;

export const Grafana = () => {
  const { entity } = useEntity();
  const config = useApi(configApiRef);
  const argocdServiceURL = config.getString("backend.baseUrl")
  const [data, setData] = React.useState({
    env: '',
    context: '',
    service: '',
    services: [],
    loading: false,
    disableEnv: false,
    disableService: true,
    showAlert: false,
    errorMessage: '',
    errorTitle:''
  });
  const repository = entity.metadata.name;
  const environments: Array<any> = ["dev", "stage", "prod"];

  const initConfig = async () => {
    if (staticContext) return;    
    if (error) return;

    const queryString: string = [
      `repository=${repository}`
    ].join('&');
    const url: string = argocdServiceURL + `/api/argocd/context?${queryString}`;
    
    try{
      const {body, res} = await fetchAuthorization(url);

      staticContext = body.name;

      if(res.status == 401){
        error = true;
        setData({...data, disableEnv: true, showAlert: true, errorMessage: 'Você não tem autorização para acessar esse módulo.',  errorTitle:'Acesso não autorizado.'});
        return;
      }
      if(staticContext === ""){
        error = true;
        setData({...data, showAlert: true, errorMessage: 'Nenhum contexto relacionado a esse repositório.', errorTitle:'Verifique se existem contextos relacionados a esse repositório.'});
      }else{
        setData({...data, context: staticContext});
      }
    }catch (error) {
      console.error(error);
    }
  };
  initConfig();

  const envHandler = async (event: any) => {
    setData({
      ...data,
      env: event.target.value,
      service: '',
      services: [],
      disableService: true,
      loading: true
    });

    const queryString: string = [
      `environment=${event.target.value}`,
      `repository=${repository}`,
      `context=${staticContext}`
    ].join('&');
    const url: string = argocdServiceURL + '/api/argocd/services?' + queryString;
    
    const {body, res} = await fetchAuthorization(url);

    if (res.status != 200) {
      let temp_data = {
        ...data,
        env: event.target.value,
        context: staticContext,
        service: '',
        services: [],
        disableService: true,
        loading: false
      };

      if(res.status == 401){
        error = true;
        setData({
          ...temp_data, 
          disableEnv: true,
          showAlert: true,
          errorMessage: 'Você não tem autorização para acessar esse módulo.',  
          errorTitle:'Acesso não autorizado.'
        });
      }else{
        setData({
          ...temp_data,
          showAlert: true,
          errorMessage: 'Nenhum serviço relacionado a esse repositório.', 
          errorTitle:'Verifique se existem serviços relacionados a esse repositório.'
        });
      }
      return;
    }

    let updatedServices = [];
    const jsonResult = body;

    updatedServices = jsonResult.map((item: any) => {
      return {name: item.appname}
    });
    
    setData({
      ...data,
      env: event.target.value,
      context: staticContext,
      services: updatedServices,
      disableService: false,
      loading: false,
      showAlert: false
    });
  };

  const serviceHandler = (event: any) => {
    setData({...data, service: event.target.value});
  };


  return (
    <Card>
      <CardContent>
        <Box sx={{ minWidth: 120 }}>
          <Progress style={{display: data.loading ? 'block' : 'none'}}/>

          <FormControl fullWidth>
            <InputLabel id="select-service-label">Ambiente:</InputLabel>
            <Select
              disabled={data.disableEnv}
              labelId="select-service-label"
              id="countrySelect" 
              label="Ambiente"
              value={data.env}
              onChange={(event) => envHandler(event)}>
              {environments.map((item, index: number) => (
              <MenuItem key={index} value={item}>
                <em>{item}</em>
              </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="select-service-label">Selecione o Serviço:</InputLabel>
            <Select
            disabled={data.disableService}
              labelId="select-service-label" 
              id="countrySelect" 
              label="Serviço"
              value={data.service}
              onChange={(event) => serviceHandler(event)}>
              {data.services.map(({name}, index: number) => (
              <MenuItem key={index} value={name}>
                <em>{name}</em>
              </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box style={{marginTop: "20px"}}>
        {data.showAlert &&(
            <WarningPanel title={data.errorTitle}>
                {data.errorMessage.includes('contexto') ? (
                <React.Fragment>
                  {data.errorMessage}{' '}
                  <Link to="https://pravaler.atlassian.net/wiki/spaces/devops/pages/1678114823/T+picos+Gerais">
                    Mais informações consulte a documentação
                  </Link>
                </React.Fragment>
              ) : (
                data.errorMessage
              )}
            </WarningPanel>
          )}
          <GrafanaLink data={{service: data.service, env: data.env, context: data.context}} />
        </Box>
        <Box style={{marginTop: "20px"}}>
          <GrafanaFrame data={{service: data.service, env: data.env, context: data.context}} />
        </Box>
      </CardContent>
    </Card>
  );
};