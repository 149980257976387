import React from 'react';
import { Grid } from '@material-ui/core';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import fetchAuthorization from '../../fetchAutorization';
let staticConfig: any;

export const GrafanaFrame = (props: any) => {
  const [data, setData] = React.useState({configLoaded: false});

  const initConfig = async () => {
    if (staticConfig) return;
    
    const config = useApi(configApiRef);
    const argocdServiceURL = config.getString("backend.baseUrl") + '/api/argocd/config';

    try {
      const {body} = await fetchAuthorization(argocdServiceURL);
      staticConfig = body;
      setData({...data, configLoaded: true});
    } catch (error) {
      console.error(error);
    }
  }
  
  initConfig();
  
  if (!props.data.service || !props.data.env || !props.data.context) {
    return (<Grid><b></b></Grid>);
  }
  if (!staticConfig[props.data.env]) {
    return (<Grid><b>Nenhuma configuração para o ambiente selecionado!</b></Grid>);
  }
  if (!staticConfig[props.data.env][props.data.context]) {
    return (<Grid><b>Nenhuma configuração para o ambiente selecionado!</b></Grid>);
  }

  let panelConfig: any = staticConfig[props.data.env][props.data.context];
  let grafanaPanelUrl: string = `${panelConfig.dashboard}&var-BRANCH=${props.data.service}`

  return (
    <Grid>
      <iframe 
        src={grafanaPanelUrl}
        width="100%" 
        height="600px"
        style={{border: '0'}}>
      </iframe>
    </Grid>
  );
};
