import React from 'react';
import { FieldProps, FieldValidation } from '@rjsf/core';
import FormControl from '@material-ui/core/FormControl';
import {identityApiRef, useApi} from "@backstage/core-plugin-api";
import {FormHelperText, InputLabel, MenuItem, Select} from "@material-ui/core";
import {useAsync} from "react-use";

interface IGithubGroup {
  groups: string[];
}

export const formatUserGroups = (ownershipEntityRefs: string[]) => {
  const regex = /^group:default\/(((tribo|chapter|squad)-(([a-z0-9-_])(?!owner))+)|(platform))$/g;
  const matchedGroups: string[] = ownershipEntityRefs.filter((group) => group.match(regex) );

  matchedGroups.forEach(function(_: any, index: number, matchedGroup: any) {
    matchedGroup[index] = matchedGroup[index].replace(/group:default\//, "");
  });
  
  if(matchedGroups.length == 0) {
    throw new Error("No valid Github Groups found.");
  }

  return matchedGroups;
}

export const GithubGroupPicker = ({
                                    onChange,
                                    rawErrors,
                                    required,
                                    formData,
                                  }: FieldProps<string>) => {
  
  const identityApi = useApi(identityApiRef);
  
  const [data, setData] = React.useState<IGithubGroup>({
    groups: []
  });
  
  useAsync(async () => {
    const identity = await identityApi.getBackstageIdentity();
    const ownershipEntityRefs = identity.ownershipEntityRefs;

    try {
      setData({...data, groups: formatUserGroups(ownershipEntityRefs)});
    } catch(e: any) {
      console.error(e.message)
    }
  });
  
  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <InputLabel htmlFor="githubGroupName">Team</InputLabel>
      <Select
        id="githubGroupName"
        aria-describedby="entityName"
        onChange={(e: { target: { value: any; }; }) => onChange(e.target?.value)}
      >
        {data.groups.map((element) => (
          <MenuItem key={element} value={element}>
            <em>{element}</em>
          </MenuItem>
        ))}
      </Select>
      <FormHelperText id="entityName">
        Choose the responsible tribe, squad or chapter
      </FormHelperText>
    </FormControl>
  );
};

export const validateSelectedValue = (value: string) => {
  const groupCase = /^(((tribo|chapter|squad)-(([a-z0-9-_])(?!owner))+)|(platform))$/g.test(value);

  if (!groupCase) {
    throw new Error(`Only groups that matches "tribo-[any]", "squad-[any]", "chapter-[any]" or "platform" are allowed (Except owner groups).`)
  }
  
  return true
}

export const validateGithubGroup = (
  value: string,
  validation: FieldValidation,
) => {
  try {
    validateSelectedValue(value)
  } catch(e: any) {
    validation.addError(
      e.message,
    );
  }


};