import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import fetchAuthorization from '../../fetchAutorization';
let staticConfig: any;

export const LogsLink = (props: any) => {

  const [data, setData] = React.useState({configLoaded: false});

  const initConfig = async () => {
    if (staticConfig) return;

    const config = useApi(configApiRef);

    const argocdServiceURL = config.getString("backend.baseUrl") + '/api/argocd/config';

    try {
      const {body} = await fetchAuthorization(argocdServiceURL);
      staticConfig = body;
      setData({...data, configLoaded: true});
    } catch (error) {
      console.error(error);
    }
  }

  initConfig();

  if (!props.data.service || !props.data.env || !props.data.context) {
    return (<Grid><b></b></Grid>); 
  }
  if (!staticConfig[props.data.env]) {
    return (<Grid><b>Nenhuma configuração para o ambiente selecionado!</b></Grid>);
  }
  if (!staticConfig[props.data.env][props.data.context]) {
    return (<Grid><b>Nenhuma configuração para o ambiente e contexto selecionado!</b></Grid>);
  }

  let pod_name = props.data.pod ? `resource.labels.pod_name%3D%22${props.data.pod}%22` : "";

  let cloudLoggingsPanelUrl: string = `https://console.cloud.google.com/logs/query;query=resource.type%3D%22k8s_container%22%0Aresource.labels.project_id%3D%22${props.data.context}-${props.data.env}%22%0Aresource.labels.cluster_name%3D%22${props.data.context}-${props.data.env}%22%0Aresource.labels.namespace_name%3D%22${props.data.service}%22%0A${pod_name};duration=PT1H?project=${props.data.context}-${props.data.env}`

  return (
      <Button style={{marginLeft: "20px"}}
        variant="contained"
        color="primary"
        target="_blank"
        href={cloudLoggingsPanelUrl}
      >Ir para Cloud Loggings</Button>
  );
};