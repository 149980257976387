import {
  scaffolderPlugin,
  createScaffolderFieldExtension,
} from '@backstage/plugin-scaffolder';
import {
  GithubGroupPicker,
  validateGithubGroup,
} from './GithubGroupExtension';

export const GithubGroupFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'GithubGroupPicker',
    component: GithubGroupPicker,
    validation: validateGithubGroup,
  }),
);